import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { concatLatestFrom } from '@ngrx/operators'
import { catchError, exhaustMap, map, of } from 'rxjs'
import { clone } from 'ramda'

import * as IotListActions from '@/stores/actions/iot-list.action'
import { selectIotListSearchParams } from '@/stores/selectors/iot-list.selector'

import { IotService } from '@/app/core/requests/iot.service'
import { failureSharedParkinglotList } from '@/stores/actions/sharedparkinglot-list.action'

import type { HttpErrorResponse } from '@angular/common/http'

const getIotListEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), iotService = inject(IotService)) => {
    return actions$.pipe(
      ofType(IotListActions.getIotList),
      concatLatestFrom(() => store.select(selectIotListSearchParams)),
      exhaustMap(([_, params]) => {
        const searchParams = clone(params)

        if (!searchParams.search_keyword) {
          delete searchParams.search_keyword
          delete searchParams.search_type
        }

        if (!searchParams.filter_address || searchParams.filter_address === '구 정보') {
          delete searchParams.filter_address
        }

        return iotService.getIots(searchParams).pipe(
          map((response) => IotListActions.successIotList({ payload: response })),
          catchError((error: HttpErrorResponse) => {
            alert(error.error?.message || 'Failed to fetch iot list')
            return of(failureSharedParkinglotList())
          })
        )
      })
    )
  },
  { functional: true }
)

const saveIotListEffect = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(IotListActions.successIotList),
      map(({ payload }) => {
        return IotListActions.saveIotList({
          iotList: payload.items,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          guList: [{ gu: '구 정보' }, ...payload.gu_list],
          count: payload.count,
          total: payload.total
        })
      })
    )
  },
  { functional: true }
)

export { getIotListEffect, saveIotListEffect }
