import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { concatLatestFrom } from '@ngrx/operators'
import { exhaustMap, map, catchError, of } from 'rxjs'
import { lt, pipe, prop } from 'ramda'

import { StorageService } from '@/app/core/services/storage.service'
import { AuthService } from '@/app/core/requests/auth.service'
import { selectAuthAllState } from '@/stores/selectors/auth.selector'
import * as AuthActions from '@/stores/actions/auth.action'

const loginStartEffect = createEffect(
  (
    actions$ = inject(Actions),
    authService = inject(AuthService),
    storageService = inject(StorageService),
    router = inject(Router),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(AuthActions.loginStart),
      concatLatestFrom(() => store.select(selectAuthAllState)),
      exhaustMap(([_, { email, password }]) => {
        return authService.login(email, password).pipe(
          map((response) => {
            const { user } = response
            const isModuAdmin = pipe(prop('roleLevel'), lt(50))(user)
            storageService.setItem('loginInfo', user)
            storageService.setItem('isLoggedIn', true)
            router.navigate([isModuAdmin ? 'dashboard' : 'partner'])
            return AuthActions.loginSuccess()
          }),
          catchError(() => {
            return of(AuthActions.loginFailure())
          })
        )
      })
    )
  },
  { functional: true }
)

const clearLoginInfoEffect = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(() => {
        return AuthActions.clearLoginInfo()
      })
    )
  },
  { functional: true }
)

const logoutStartEffect = createEffect(
  (
    actions$ = inject(Actions),
    authService = inject(AuthService),
    storageService = inject(StorageService),
    router = inject(Router)
  ) => {
    return actions$.pipe(
      ofType(AuthActions.logoutStart),
      exhaustMap(() => {
        return authService.logout().pipe(
          map(() => {
            storageService.removeItem('loginInfo')
            storageService.setItem('isLoggedIn', false)
            router.navigate(['login'])
            return AuthActions.logoutSuccess()
          })
        )
      }),
      catchError(() => {
        return of(AuthActions.logoutFailure())
      })
    )
  },
  { functional: true }
)

export { loginStartEffect, clearLoginInfoEffect, logoutStartEffect }
