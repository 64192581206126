import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { concatLatestFrom } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { clone } from 'ramda'
import { catchError, exhaustMap, map, of } from 'rxjs'

import { SharedParkinglotService } from '@/app/core/requests/sharedparkinglot.service'
import * as SharedParkinglotListActions from '@/stores/actions/sharedparkinglot-list.action'
import { selectSharedParkinglotFindParams } from '@/stores/selectors/sharedparkinglot-list.selector'

const getSharedParkinglotListEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), sharedParkinglotService = inject(SharedParkinglotService)) => {
    return actions$.pipe(
      ofType(SharedParkinglotListActions.getSharedParkinglotList),
      concatLatestFrom(() => store.select(selectSharedParkinglotFindParams)),
      exhaustMap(([_, params]) => {
        const findParam = clone(params)
        if (params.dongname === '동') {
          delete findParam.dongname
        }
        if (params.sensor === '-1') {
          delete findParam.sensor
        }
        if (params.status === '0') {
          delete findParam.status
        }
        if (!params.keyword) {
          delete findParam.key
          delete findParam.keyword
        }
        return sharedParkinglotService.getParkinglotList(findParam).pipe(
          map((response) => SharedParkinglotListActions.successSharedParkinglotList({ payload: response })),
          catchError(() => of(SharedParkinglotListActions.failureSharedParkinglotList()))
        )
      })
    )
  },
  { functional: true }
)

const saveSharedParkinglotListEffect = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(SharedParkinglotListActions.successSharedParkinglotList),
      map(({ payload }) => {
        return SharedParkinglotListActions.saveSharedParkinglotList({
          payload: { parkinglots: payload.parkinglots, count: payload.count }
        })
      })
    )
  },
  { functional: true }
)

export { getSharedParkinglotListEffect, saveSharedParkinglotListEffect }
