import { combineReducers } from '@ngrx/store'
import { authReducer } from '@/stores/reducers/auth.reducer'
import { sharedParkinglotListReducer } from '@/stores/reducers/sharedparkinglot-list.reducer'
import { iotListReducer } from '@/stores/reducers/iot-list.reducer'

export const rootReducer = combineReducers({
  auth: authReducer,
  sharedParkinglotList: sharedParkinglotListReducer,
  iotList: iotListReducer
})

export type RootStateType = ReturnType<typeof rootReducer>
