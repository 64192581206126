import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { BehaviorSubject, defer, EMPTY, Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { environment } from '@/environments/environment'

import type {
  IotAuthType,
  IotHistoryListParamsType,
  IotHistoryListResponseType,
  IotListRequestParamsType,
  IotListResponseType,
  IotStatisticResponseType
} from '@/types/api/iot-api.type'

@Injectable({
  providedIn: 'root'
})
export class IotService {
  constructor(private http: HttpClient) {}

  private iotAuth = new BehaviorSubject<{ expireDate: number | null; accessToken: string | null }>({
    expireDate: null,
    accessToken: null
  })

  // IoT 인증 정보 가져오기
  private getIotAuth(): Observable<IotAuthType> {
    return defer(() =>
      this.http.get<IotAuthType>(`${environment.apiServer}iot/auth`).pipe(
        catchError((error: HttpErrorResponse) => {
          alert(error.error?.message || 'Failed to fetch auth')
          return EMPTY
        })
      )
    )
  }

  // 인증 정보 설정
  private setAuth(): Observable<boolean> {
    return this.getIotAuth().pipe(
      map((response) => {
        const expireDate = Date.now() + response.expires_in * 1000
        const accessToken = response.access_token
        this.iotAuth.next({ expireDate, accessToken })
        return !!response.access_token
      })
    )
  }

  // 인증 상태 확인
  checkAuth(): Observable<boolean> {
    return defer(() => {
      const auth = this.iotAuth.value
      const isExpired = !auth.accessToken || (auth.expireDate ?? 0) < Date.now() + 1000 * 60 * 5
      if (isExpired) {
        return this.setAuth()
      }
      return of(true)
    })
  }

  // IoT 기기 목록 가져오기
  getIots(reqParams: IotListRequestParamsType) {
    return this.checkAuth().pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          alert('인증에 실패했습니다.')
          return EMPTY
        }
        const headers = {
          'iot-token': this.iotAuth.value.accessToken ? `Bearer ${this.iotAuth.value.accessToken}` : ''
        }
        const params = { ...reqParams }
        return this.http.get<IotListResponseType>(`${environment.apiServer}iots`, { headers, params })
      })
    )
  }

  // IoT 운영 상태 변경
  changeOperation(payload: { code: string; status: string; reason?: string }) {
    return this.checkAuth().pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          alert('인증에 실패했습니다.')
          return EMPTY
        }
        const headers = {
          'iot-token': this.iotAuth.value.accessToken ? `Bearer ${this.iotAuth.value.accessToken}` : ''
        }
        return this.http.put(`${environment.apiServer}iots/${payload.code}`, payload, { headers })
      })
    )
  }

  // IoT 이력 가져오기
  getHistoryList(historyParam: IotHistoryListParamsType) {
    return this.checkAuth().pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          alert('인증에 실패했습니다.')
          return EMPTY
        }
        const headers = {
          'iot-token': this.iotAuth.value.accessToken ? `Bearer ${this.iotAuth.value.accessToken}` : ''
        }
        const params = { ...historyParam }
        return this.http.get<IotHistoryListResponseType>(`${environment.apiServer}iots/${historyParam.code}/history`, {
          headers,
          params
        })
      })
    )
  }

  // IoT 통계 가져오기
  getStatistic(param: { begin: string; until: string }) {
    return this.checkAuth().pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          alert('인증에 실패했습니다.')
          return EMPTY
        }
        const headers = {
          'iot-token': this.iotAuth.value.accessToken ? `Bearer ${this.iotAuth.value.accessToken}` : ''
        }
        const params = { ...param }
        return this.http
          .get<IotStatisticResponseType>(`${environment.apiServer}iots/statistic`, { headers, params })
          .pipe(
            catchError((error: HttpErrorResponse) => {
              alert(error.error?.message || 'Failed to fetch statistics')
              return EMPTY
            })
          )
      })
    )
  }
}
