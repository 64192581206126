import { createSelector } from '@ngrx/store'

import type { RootStateType } from '@/stores/reducers'

const sharedParkinglotListStoreSelector = (store: { root: RootStateType }) => store.root.sharedParkinglotList

export const selectSharedParkinglotFindParams = createSelector(
  sharedParkinglotListStoreSelector,
  (state) => state.findParams
)
export const selectSharedParkinglotList = createSelector(
  sharedParkinglotListStoreSelector,
  (state) => state.sharedParkinglots
)
export const selectSharedParkinglotCount = createSelector(sharedParkinglotListStoreSelector, (state) => state.count)
export const selectSharedParkinglotTotalPage = createSelector(
  sharedParkinglotListStoreSelector,
  (state) => state.totalPage
)
