import { createReducer, on } from '@ngrx/store'
import { produce } from 'immer'

import * as IotListActions from '@/stores/actions/iot-list.action'

import type { IotGuListType, IotListRequestParamsType, IotListType } from '@/types/api/iot-api.type'
import type { RequestStatus } from '@/stores/reducers/auth.reducer'

interface IotListStateType {
  count: number
  total: number
  totalPage: number
  searchParams: IotListRequestParamsType
  iotList: IotListType[]
  guList: IotGuListType[]
  status: RequestStatus
}

const initialState: IotListStateType = {
  count: 0,
  total: 0,
  totalPage: 0,
  searchParams: {
    page: 1,
    size: 20,
    search_type: 'address',
    search_keyword: ''
  },
  iotList: [],
  guList: [],
  status: 'idle'
}

export const iotListReducer = createReducer<IotListStateType>(
  initialState,
  on(IotListActions.changeParams, (state, { payload }) =>
    produce(state, (draft) => {
      draft.searchParams = { ...state.searchParams, ...payload }
    })
  ),
  on(IotListActions.saveIotList, (state, { iotList, count, total, guList }) =>
    produce(state, (draft) => {
      draft.iotList = iotList
      draft.count = count
      draft.total = total
      draft.guList = guList
      draft.totalPage = Math.ceil(count / state.searchParams.size)
      draft.status = 'idle'
    })
  ),
  on(IotListActions.getIotList, (state) =>
    produce(state, (draft) => {
      draft.status = 'pending'
    })
  ),
  on(IotListActions.successIotList, (state) =>
    produce(state, (draft) => {
      draft.status = 'success'
    })
  ),
  on(IotListActions.failureIotList, (state) =>
    produce(state, (draft) => {
      draft.status = 'failure'
    })
  )
)
