import * as AuthEffects from '@/stores/effects/auth.effect'
import * as SharedParkinglotListEffect from '@/stores/effects/sharedparkinglot-list.effect'
import * as IotListEffect from '@/stores/effects/iot-list.effect'

import type { FunctionalEffect } from '@ngrx/effects'

export const rootEffects: Record<string, FunctionalEffect> = {
  ...AuthEffects,
  ...SharedParkinglotListEffect,
  ...IotListEffect
}
