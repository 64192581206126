import { createReducer, on } from '@ngrx/store'
import { produce } from 'immer'

import * as AuthActions from '@/stores/actions/auth.action'

export type RequestStatus = 'idle' | 'pending' | 'success' | 'failure'

export interface AuthStateType {
  email: string
  password: string
  status: RequestStatus
}

const initialState: AuthStateType = {
  email: '',
  password: '',
  status: 'idle'
}

export const authReducer = createReducer<AuthStateType>(
  initialState,
  on(AuthActions.saveUserEmailAndPassword, (state, { email, password }) =>
    produce(state, (draft) => {
      if (email) draft.email = email
      if (password) draft.password = password
    })
  ),
  on(AuthActions.clearLoginInfo, (state) =>
    produce(state, (draft) => {
      draft.email = ''
      draft.password = ''
    })
  ),
  on(AuthActions.loginStart, (state) =>
    produce(state, (draft) => {
      draft.status = 'pending'
    })
  ),
  on(AuthActions.loginSuccess, (state) =>
    produce(state, (draft) => {
      draft.status = 'success'
    })
  ),
  on(AuthActions.loginFailure, (state) =>
    produce(state, (draft) => {
      draft.status = 'failure'
    })
  )
)
