import { createAction, props } from '@ngrx/store'

import type {
  IotGuListType,
  IotListRequestParamsType,
  IotListResponseType,
  IotListType
} from '@/types/api/iot-api.type'

const CHANGE_PARAMS = '[IoTList] Change Params'
const SAVE_IOT_LIST = '[IoTList] Save IoT List'
const GET_IOT_LIST = '[IoTList] Get IoT List'
const FAILURE_IOT_LIST = '[IoTList] Failure IoT List'
const SUCCESS_IOT_LIST = '[IoTList] Success IoT List'

export const changeParams = createAction<typeof CHANGE_PARAMS, { payload: Partial<IotListRequestParamsType> }>(
  CHANGE_PARAMS,
  props<{ payload: Partial<IotListRequestParamsType> }>()
)
export const saveIotList = createAction<
  typeof SAVE_IOT_LIST,
  { iotList: IotListType[]; guList: IotGuListType[]; total: number; count: number }
>(SAVE_IOT_LIST, props<{ iotList: IotListType[]; guList: IotGuListType[]; total: number; count: number }>())
export const getIotList = createAction<typeof GET_IOT_LIST>(GET_IOT_LIST)
export const failureIotList = createAction<typeof FAILURE_IOT_LIST>(FAILURE_IOT_LIST)
export const successIotList = createAction<typeof SUCCESS_IOT_LIST, { payload: IotListResponseType }>(
  SUCCESS_IOT_LIST,
  props<{ payload: IotListResponseType }>()
)
