import { createAction, props } from '@ngrx/store'

import type {
  SharedParkinglotListCountType,
  SharedParkinglotListParamsType,
  SharedParkinglotListResponseType,
  SharedParkinglotListType
} from '@/types/api/sharedparkinglot-api.type'

const CHANGE_PARAMS = '[SharedParkinglotList] Change Params'
const SAVE_SHARED_PARKINGLOT_LIST = '[SharedParkinglotList] Save Shared Parkinglot List'
const GET_SHARED_PARKINGLOT_LIST = '[SharedParkinglotList] Get Shared Parkinglot List'
const FAILURE_SHARED_PARKINGLOT_LIST = '[SharedParkinglotList] Failure Shared Parkinglot List'
const SUCCESS_SHARED_PARKINGLOT_LIST = '[SharedParkinglotList] Success Shared Parkinglot List'

export const changeParams = createAction<typeof CHANGE_PARAMS, { payload: Partial<SharedParkinglotListParamsType> }>(
  CHANGE_PARAMS,
  props<{ payload: Partial<SharedParkinglotListParamsType> }>()
)
export const saveSharedParkinglotList = createAction<
  typeof SAVE_SHARED_PARKINGLOT_LIST,
  { payload: { parkinglots: SharedParkinglotListType[]; count: SharedParkinglotListCountType } }
>(
  SAVE_SHARED_PARKINGLOT_LIST,
  props<{ payload: { parkinglots: SharedParkinglotListType[]; count: SharedParkinglotListCountType } }>()
)
export const getSharedParkinglotList = createAction<typeof GET_SHARED_PARKINGLOT_LIST>(GET_SHARED_PARKINGLOT_LIST)
export const failureSharedParkinglotList =
  createAction<typeof FAILURE_SHARED_PARKINGLOT_LIST>(FAILURE_SHARED_PARKINGLOT_LIST)
export const successSharedParkinglotList = createAction<
  typeof SUCCESS_SHARED_PARKINGLOT_LIST,
  { payload: SharedParkinglotListResponseType }
>(SUCCESS_SHARED_PARKINGLOT_LIST, props<{ payload: SharedParkinglotListResponseType }>())
