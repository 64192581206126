import { createReducer, on } from '@ngrx/store'
import { produce } from 'immer'

import * as SharedParkinglotListActions from '@/stores/actions/sharedparkinglot-list.action'

import type {
  SharedParkinglotListCountType,
  SharedParkinglotListParamsType,
  SharedParkinglotListType
} from '@/types/api/sharedparkinglot-api.type'
import type { RequestStatus } from '@/stores/reducers/auth.reducer'

interface SharedParkinglotListStateType {
  findParams: SharedParkinglotListParamsType
  sharedParkinglots: SharedParkinglotListType[]
  count: SharedParkinglotListCountType
  totalPage: number
  status: RequestStatus
}

const initialState: SharedParkinglotListStateType = {
  findParams: {
    page: 0,
    guSeq: 0,
    limit: 20,
    key: 'all',
    status: '0',
    keyword: '',
    sensor: '-1',
    orderByModifyDate: 'off'
  },
  count: { activeTotal: 0, assigned: 0, notAssigned: 0, total: 0 },
  totalPage: 0,
  sharedParkinglots: [],
  status: 'idle'
}

export const sharedParkinglotListReducer = createReducer<SharedParkinglotListStateType>(
  initialState,
  on(SharedParkinglotListActions.changeParams, (state, { payload }) =>
    produce(state, (draft) => {
      draft.findParams = { ...state.findParams, ...payload }
    })
  ),
  on(SharedParkinglotListActions.saveSharedParkinglotList, (state, { payload }) =>
    produce(state, (draft) => {
      draft.sharedParkinglots = payload.parkinglots
      draft.count = payload.count
      draft.totalPage = Math.ceil(payload.count.total / state.findParams.limit)
      draft.status = 'idle'
    })
  ),
  on(SharedParkinglotListActions.getSharedParkinglotList, (state) =>
    produce(state, (draft) => {
      draft.status = 'pending'
    })
  ),
  on(SharedParkinglotListActions.successSharedParkinglotList, (state) =>
    produce(state, (draft) => {
      draft.status = 'success'
    })
  ),
  on(SharedParkinglotListActions.failureSharedParkinglotList, (state) =>
    produce(state, (draft) => {
      draft.status = 'failure'
    })
  )
)
