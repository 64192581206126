import { Component, computed, inject, signal } from '@angular/core'
import { CommonModule, Location } from '@angular/common'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { map, Subscription } from 'rxjs'

import { AuthService } from '@/app/core/requests/auth.service'
import { StorageService } from '@/app/core/services/storage.service'
import { AdminPermissionService } from '@/app/core/services/admin-permission.service'
import { WindowWidthDirective } from '@/app/core/directives/window.directive'
import STRING from '@/string'
import { environment } from '@/environments/environment'
import { MenuCodes } from '@/enums/menu'
import { logoutStart } from '@/stores/actions/auth.action'
import { selectAuthAllState } from '@/stores/selectors/auth.selector'

import type { OnInit } from '@angular/core'
import type { AdminUserInfo } from '@/types/api/auth-api.type'
import type { FormattedMenuItem } from '@/types/client/auth-client.type'

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  imports: [CommonModule, WindowWidthDirective],
  animations: [
    trigger('collapseAnimation', [
      state('collapsed', style({ height: '0', overflow: 'hidden', display: 'none' })),
      state('expanded', style({ height: '*', overflow: 'auto', display: 'block' })),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')])
    ])
  ]
})
export class HeaderComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
    private location: Location,
    private adminPermissionService: AdminPermissionService
  ) {
    this.storageService.getStorageItemObservable<AdminUserInfo>('loginInfo').subscribe((userInfo) => {
      this.userInfo = userInfo
    })
  }
  store = inject(Store)
  loginStoreState$ = this.store.select(selectAuthAllState)
  storeSubscription!: Subscription

  STRING = STRING
  openMenuCodesForAdmin: MenuCodes[] = [MenuCodes.DASHBOARD, MenuCodes.MAP_VIEW_ALL, MenuCodes.MAP_VIEW_SHARED] // 대쉬보드, 맵뷰(전체), 맵뷰(공유)
  readonly APP_ENV = environment.appEnv

  readonly menus = signal<FormattedMenuItem[]>([])
  dropdownToggle = false
  mobileDropdownToggle = false
  mobileDropdownToggleDelay = false

  userInfo!: AdminUserInfo | null

  toggleDropdown() {
    this.dropdownToggle = !this.dropdownToggle
  }
  toggleMobileDropdown() {
    if (this.mobileDropdownToggle) {
      setTimeout(() => {
        this.mobileDropdownToggleDelay = false
      }, 300)
    } else {
      this.mobileDropdownToggleDelay = true
    }
    this.mobileDropdownToggle = !this.mobileDropdownToggle
  }

  isModuAdmin = computed(() => this.adminPermissionService.isModuAdmin())
  isPartnerAdmin = computed(() => this.adminPermissionService.isPartnerAdmin())

  redirect(uri: string) {
    if (uri.startsWith('parkinglot')) {
      if (uri.split('/')[1] === 'map') {
        window.location.href = `${window.location.origin}/map`
        return
      }
      if (uri.split('/')[1] === 'partner-map') {
        window.location.href = `${window.location.origin}/sharedparkinglot-map`
        return
      }
    }
    if (this.mobileDropdownToggle) {
      this.mobileDropdownToggle = false
    }
    this.router.navigate([uri], { replaceUrl: true })
  }

  logout() {
    this.store.dispatch(logoutStart())
  }

  ngOnInit() {
    if (this.location.path() === '/login' || this.location.path() === '/') return
    this.authService
      .getMenuList()
      .pipe(
        map((response) => {
          const { result } = response
          const newMenuList: FormattedMenuItem[] = []

          const getParentMenu = (parentMenuCode: number) => {
            return result.find((menu) => menu.menuCode === parentMenuCode) as FormattedMenuItem
          }

          result.forEach((menu) => {
            const parentMenu = getParentMenu(menu.parentMenuCode)

            if (parentMenu) {
              parentMenu.subMenu = [...(parentMenu.subMenu || []), menu]
              parentMenu.isExpanded = false
            } else {
              newMenuList.push(menu)
            }
          })

          return newMenuList
        })
      )
      .subscribe({
        next: (formattedData) => {
          this.menus.set(formattedData)
        },
        error: (err) => console.log(err)
      })
  }
}
