import { createAction, props } from '@ngrx/store'

const SAVE_USER_EMAIL_AND_PASSWORD = '[Auth] Save User Email And Password'
const CLEAR_LOGIN_INFO = '[Auth] Clear Login Info'
const LOGIN_START = '[Auth] Login Start'
const LOGIN_SUCCESS = '[Auth] Login Success'
const LOGIN_FAILURE = '[Auth] Login Failure'

const LOGOUT_START = '[Auth] Logout Start'
const LOGOUT_SUCCESS = '[Auth] Logout Success'
const LOGOUT_FAILURE = '[Auth] Logout Failure'

export const saveUserEmailAndPassword = createAction<
  typeof SAVE_USER_EMAIL_AND_PASSWORD,
  { email?: string; password?: string }
>(SAVE_USER_EMAIL_AND_PASSWORD, props<{ email: string; password: string }>())
export const clearLoginInfo = createAction<typeof CLEAR_LOGIN_INFO>(CLEAR_LOGIN_INFO)
export const loginStart = createAction<typeof LOGIN_START>(LOGIN_START)
export const loginSuccess = createAction<typeof LOGIN_SUCCESS>(LOGIN_SUCCESS)
export const loginFailure = createAction<typeof LOGIN_FAILURE>(LOGIN_FAILURE)

export const logoutStart = createAction<typeof LOGOUT_START>(LOGOUT_START)
export const logoutSuccess = createAction<typeof LOGOUT_SUCCESS>(LOGOUT_SUCCESS)
export const logoutFailure = createAction<typeof LOGOUT_FAILURE>(LOGOUT_FAILURE)
