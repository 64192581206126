import { createSelector } from '@ngrx/store'

import type { RootStateType } from '@/stores/reducers'

const iotListStoreSelector = (store: { root: RootStateType }) => store.root.iotList

export const selectIotListSearchParams = createSelector(iotListStoreSelector, (state) => state.searchParams)
export const selectIotList = createSelector(iotListStoreSelector, (state) => state.iotList)
export const selectIotGuList = createSelector(iotListStoreSelector, (state) => state.guList)
export const selectIotListCount = createSelector(iotListStoreSelector, (state) => state.count)
export const selectIotListTotal = createSelector(iotListStoreSelector, (state) => state.total)
export const selectIotListTotalPage = createSelector(iotListStoreSelector, (state) => state.totalPage)
