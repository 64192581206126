import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '@/environments/environment'

import type {
  SharedParkinglotDetailResponseType,
  SharedParkinglotDongListParamsType,
  SharedParkinglotDongListResponseType,
  SharedParkinglotListParamsType,
  SharedParkinglotListResponseType,
  SharedParkinglotParkingListType,
  SharedParkinglotTimeResponseType,
  SharedParkinglotTimeType
} from '@/types/api/sharedparkinglot-api.type'

@Injectable({
  providedIn: 'root'
})
export class SharedParkinglotService {
  constructor(private http: HttpClient) {}

  getDongList(param: SharedParkinglotDongListParamsType) {
    const params = { ...param }
    return this.http.get<SharedParkinglotDongListResponseType>(`${environment.apiServer}parkinglots/share/dong`, {
      params
    })
  }

  getParkinglotList(param: SharedParkinglotListParamsType) {
    const params = { ...param }
    return this.http.get<SharedParkinglotListResponseType>(`${environment.apiServer}parkinglots/share`, { params })
  }

  getParkinglot(shareSeq: number) {
    return this.http.get<SharedParkinglotDetailResponseType>(`${environment.apiServer}parkinglots/share/${shareSeq}`)
  }

  getParkingsList(shareSeq: number) {
    return this.http.get<SharedParkinglotParkingListType[]>(
      `${environment.apiServer}parkinglot/share/${shareSeq}/parkings`
    )
  }

  deleteParkinglot(seq: number) {
    return this.http.delete<void>(`${environment.apiServer}parkinglots/share/${seq}`)
  }

  getSharedParkinglotTime(shareSeq: number) {
    return this.http.get<SharedParkinglotTimeResponseType>(`${environment.apiServer}parkinglots/share/time/${shareSeq}`)
  }

  updateTime(payload: SharedParkinglotTimeType[]) {
    return this.http.put<void>(`${environment.apiServer}parkinglots/share/time`, payload)
  }
}
